import React from 'react';

function RegistriesIcon() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 16.5C14.6867 16.5 15.8467 16.1481 16.8334 15.4888C17.8201 14.8295 18.5892 13.8925 19.0433 12.7961C19.4974 11.6997 19.6162 10.4933 19.3847 9.32946C19.1532 8.16557 18.5818 7.09648 17.7426 6.25736C16.9035 5.41825 15.8344 4.8468 14.6705 4.61529C13.5067 4.38378 12.3003 4.5026 11.2039 4.95673C10.1075 5.41085 9.17047 6.17989 8.51118 7.16658C7.85189 8.15328 7.5 9.31331 7.5 10.5C7.5 12.0913 8.13214 13.6174 9.25736 14.7426C10.3826 15.8679 11.9087 16.5 13.5 16.5ZM25.5 19.5C26.39 19.5 27.26 19.2361 28.0001 18.7416C28.7401 18.2471 29.3169 17.5443 29.6575 16.7221C29.9981 15.8998 30.0872 14.995 29.9135 14.1221C29.7399 13.2492 29.3113 12.4474 28.682 11.818C28.0526 11.1887 27.2508 10.7601 26.3779 10.5865C25.505 10.4128 24.6002 10.502 23.7779 10.8425C22.9557 11.1831 22.2529 11.7599 21.7584 12.4999C21.2639 13.24 21 14.11 21 15C21 16.1935 21.4741 17.3381 22.318 18.182C23.1619 19.0259 24.3065 19.5 25.5 19.5ZM31.5 30C31.8978 30 32.2794 29.842 32.5607 29.5607C32.842 29.2794 33 28.8978 33 28.5C32.9988 27.0981 32.6047 25.7246 31.8624 24.5353C31.1201 23.3461 30.0594 22.3886 28.8005 21.7717C27.5417 21.1548 26.1351 20.903 24.7404 21.045C23.3457 21.187 22.0187 21.717 20.91 22.575C19.4408 21.1115 17.5711 20.1159 15.5367 19.7136C13.5024 19.3114 11.3945 19.5205 9.47884 20.3147C7.5632 21.1089 5.92562 22.4525 4.77261 24.1762C3.6196 25.8998 3.00281 27.9263 3 30C3 30.3978 3.15804 30.7794 3.43934 31.0607C3.72064 31.342 4.10218 31.5 4.5 31.5H22.5C22.8978 31.5 23.2794 31.342 23.5607 31.0607C23.842 30.7794 24 30.3978 24 30" fill="#3772FF" />
        </svg>
    );
}

export default RegistriesIcon;