import React from 'react';

function IssueSetpIcon() {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M50 20C47.4189 20 45.341 22.0948 45.341 24.6969V35.9694C45.341 38.5715 47.4189 40.6664 50 40.6664C52.5811 40.6664 54.6591 38.5715 54.6591 35.9694V24.6969C54.6591 22.0948 52.5811 20 50 20ZM14.5912 33.1511C11.4941 33.1511 9 35.6646 9 38.7878V76.3633C9 79.4856 11.4933 82 14.5912 82H85.4088C88.5059 82 91 79.4865 91 76.3633V38.7878C91 35.6655 88.5067 33.1511 85.4088 33.1511H56.5227V35.9694C56.5227 39.5799 53.5815 42.545 50.0002 42.545C46.4189 42.545 43.4777 39.5799 43.4777 35.9694V33.1511H14.5912ZM32.2956 45.3633C35.3837 45.3633 37.8868 47.8868 37.8868 51C37.8868 54.1132 35.3837 56.6367 32.2956 56.6367C29.2075 56.6367 26.7044 54.1132 26.7044 51C26.7044 47.8868 29.2075 45.3633 32.2956 45.3633ZM53.7272 45.3633H81.6816C82.7111 45.3633 83.545 46.2047 83.545 47.2419C83.545 48.2799 82.7104 49.1206 81.6816 49.1206H53.7272C52.6977 49.1206 51.8638 48.2792 51.8638 47.2419C51.8638 46.204 52.6984 45.3633 53.7272 45.3633ZM53.7272 55.6969H81.6816C82.7111 55.6969 83.545 56.5383 83.545 57.5756C83.545 58.6135 82.7104 59.4542 81.6816 59.4542H53.7272C52.6977 59.4542 51.8638 58.6128 51.8638 57.5756C51.8638 56.5376 52.6984 55.6969 53.7272 55.6969ZM32.2956 60.3939C39.0066 60.3939 44.4093 63.9912 44.4093 68.2323V69.7878H20.1822V68.2323C20.1822 63.9914 25.5849 60.3939 32.2959 60.3939H32.2956ZM53.7272 66.0306H81.6816C82.7111 66.0306 83.545 66.8719 83.545 67.9092C83.545 68.9471 82.7104 69.7878 81.6816 69.7878H53.7272C52.6977 69.7878 51.8638 68.9464 51.8638 67.9092C51.8638 66.8712 52.6984 66.0306 53.7272 66.0306Z" fill="#3772FF" />
        </svg>
    );
}

export default IssueSetpIcon;