import React from 'react';

function PolygonIcon({className}) {
    return (
        <svg className={className} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" r="28" fill="#F3EBFF" />
            <g clipPath="url(#clip0_19079_374484)">
                <path d="M35.438 22.5308C34.853 22.1965 34.1007 22.1965 33.4321 22.5308L28.7515 25.289L25.5753 27.0443L20.9783 29.8025C20.3932 30.1368 19.641 30.1368 18.9724 29.8025L15.3783 27.6293C14.7932 27.295 14.3753 26.6264 14.3753 25.8741V21.695C14.3753 21.0264 14.7097 20.3577 15.3783 19.9398L18.9724 17.8502C19.5574 17.5159 20.3097 17.5159 20.9783 17.8502L24.5724 20.0234C25.1574 20.3577 25.5753 21.0264 25.5753 21.7786V24.5368L28.7515 22.698V19.8562C28.7515 19.1875 28.4171 18.5189 27.7485 18.101L21.0619 14.1726C20.4768 13.8383 19.7246 13.8383 19.0559 14.1726L12.2022 18.1846C11.5335 18.5189 11.1992 19.1875 11.1992 19.8562V27.7129C11.1992 28.3816 11.5335 29.0502 12.2022 29.4681L18.9724 33.3965C19.5574 33.7308 20.3097 33.7308 20.9783 33.3965L25.5753 30.7219L28.7515 28.8831L33.3485 26.2084C33.9335 25.8741 34.6858 25.8741 35.3544 26.2084L38.9485 28.298C39.5335 28.6323 39.9515 29.301 39.9515 30.0532V34.2323C39.9515 34.901 39.6171 35.5696 38.9485 35.9875L35.438 38.0771C34.853 38.4114 34.1007 38.4114 33.4321 38.0771L29.838 35.9875C29.253 35.6532 28.835 34.9846 28.835 34.2323V31.5577L25.6589 33.3965V36.1547C25.6589 36.8234 25.9932 37.492 26.6619 37.9099L33.4321 41.8383C34.0171 42.1726 34.7694 42.1726 35.438 41.8383L42.2082 37.9099C42.7932 37.5756 43.2112 36.9069 43.2112 36.1547V28.2144C43.2112 27.5458 42.8768 26.8771 42.2082 26.4592L35.438 22.5308Z" fill="#8247E5" />
            </g>
            <defs>
                <clipPath id="clip0_19079_374484">
                    <rect width="32.0955" height="28" fill="white" transform="translate(11.1992 14)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default PolygonIcon;