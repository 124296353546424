import React from 'react';

function SucessfulIcon() {
    return (
        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M52.9299 11.5541C56.1628 6.27079 63.8372 6.27079 67.0702 11.5541C69.3737 15.3185 74.2229 16.6178 78.1 14.5095C83.5415 11.5505 90.1877 15.3877 90.3459 21.5797C90.4586 25.9915 94.0085 29.5414 98.4203 29.6541C104.612 29.8123 108.449 36.4585 105.49 41.9C103.382 45.7771 104.681 50.6263 108.446 52.9299C113.729 56.1628 113.729 63.8372 108.446 67.0702C104.681 69.3737 103.382 74.2229 105.49 78.1C108.449 83.5415 104.612 90.1877 98.4203 90.3459C94.0085 90.4586 90.4586 94.0085 90.3459 98.4203C90.1877 104.612 83.5415 108.449 78.1 105.49C74.2229 103.382 69.3737 104.681 67.0701 108.446C63.8372 113.729 56.1628 113.729 52.9299 108.446C50.6263 104.681 45.7771 103.382 41.9 105.49C36.4585 108.449 29.8123 104.612 29.6541 98.4203C29.5414 94.0085 25.9915 90.4586 21.5797 90.3459C15.3877 90.1877 11.5505 83.5415 14.5095 78.1C16.6178 74.2229 15.3185 69.3737 11.5541 67.0701C6.27079 63.8372 6.27079 56.1628 11.5541 52.9299C15.3185 50.6263 16.6178 45.7771 14.5095 41.9C11.5505 36.4585 15.3877 29.8123 21.5797 29.6541C25.9915 29.5414 29.5414 25.9915 29.6541 21.5797C29.8123 15.3877 36.4585 11.5505 41.9 14.5095C45.7771 16.6178 50.6263 15.3185 52.9299 11.5541Z" fill="#27AE60" />
            <path d="M54.6417 77L39 61.3078L43.9437 56.342L54.4886 66.9204L76.9052 43L82 47.8103L54.6417 77Z" fill="white" />
        </svg>
    );
}

export default SucessfulIcon;