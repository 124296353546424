import React from 'react';

function BeforeListDIcon() {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9672 31.5082C13.4891 31.5025 14.188 31.7446 14.943 32.686L17.4717 35.8396C17.2887 35.9589 17.1271 36.094 16.9906 36.246L14.468 35.6486L12.2421 32.8146C11.6619 32.0764 12.2445 31.5143 12.9672 31.5082ZM12.0715 30.588C11.5407 30.7761 11.1484 31.1883 10.9395 31.6642C10.7306 32.1402 10.7512 32.7616 11.1111 33.2185L12.7235 35.2754C11.2 35.0982 10.0886 35.6249 9.53721 36.414C9.22206 36.8658 9.08278 37.3915 9.17161 37.894C9.26256 38.3927 9.62283 38.8621 10.1501 39.0448C10.1501 39.0448 20.8321 42.8052 26.1976 44.6098C27.412 45.0191 28.4971 45.115 29.5527 44.8545L38.21 42.4877C39.8834 42.0302 40.5269 42.3346 41.3991 42.4877C42.3687 42.6574 44.303 42.9958 44.303 42.9958C45.0512 43.1273 45.2424 41.9912 44.4977 41.8602C44.4977 41.8602 42.5634 41.5222 41.597 41.3518C40.7215 41.1991 39.8073 40.8676 38.0333 41.3518L29.2876 43.7434C28.3734 43.9736 27.3125 43.782 26.5607 43.5219C21.2107 41.6717 10.7498 38.1323 10.5196 37.9569C10.1576 37.6784 10.2905 37.3858 10.4607 37.0778C10.7318 36.591 11.5784 36.1428 13.146 36.5161L16.5185 37.3145C16.3621 37.9871 16.8716 38.6241 17.6231 38.8806L26.767 42.0071C27.4918 42.2723 27.8667 41.1522 27.1298 40.9154L17.9832 37.7889C17.4992 37.6243 17.6211 37.3371 17.8116 37.0359C18.0805 36.6111 19.0327 36.2318 20.6006 36.6504L28.0846 38.6561C28.1784 38.6795 28.2737 38.6824 28.3643 38.659L35.1675 36.97C37.051 36.4998 38.2452 36.578 41.1459 37.5468C41.8646 37.7886 42.2185 36.6948 41.4997 36.4559C38.5194 35.4572 36.9338 35.3437 34.8972 35.8488L28.2353 37.5079L20.887 35.535C20.0024 35.2986 19.2299 35.2777 18.5815 35.4034L15.8198 31.9566C15.2062 31.1917 14.5255 30.7464 13.8745 30.5338C13.2335 30.3248 12.6412 30.3875 12.0724 30.5882L12.0715 30.588Z" fill="black" />
            <path d="M36.8524 32.4879C35.2497 32.542 33.5925 33.1401 30.9751 34.3863L28.1277 35.746C27.3936 36.0569 27.912 37.1633 28.6109 36.7857L31.4615 35.4289C34.0349 34.1996 35.5381 33.6836 36.8911 33.6354C38.2445 33.5907 39.5495 34.0148 41.7459 34.8067L44.3041 35.2585C45.0822 35.4433 45.2921 34.2216 44.4967 34.129L42.0319 33.701C39.8888 32.93 38.4296 32.4375 36.8524 32.4879Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M22.424 15.4441H31.7052C32.3067 16.3286 33.4273 17.0816 34.5916 17.0942L34.5742 21.2606C33.5928 21.671 32.7938 22.6386 32.4751 23.6496L10.5891 23.65C10.5568 22.6329 10.0171 21.5812 9.22975 20.9359L9.21838 18.736C9.21483 17.9318 8.08643 17.9106 8.08643 18.7147L8.08927 21.2313C8.08643 21.4349 8.18662 21.6262 8.35681 21.731C9.1644 22.2423 9.58327 23.1801 9.427 24.1334C9.36802 24.4829 9.63592 24.8032 9.98731 24.8032H32.9084C33.1859 24.8032 33.4211 24.6036 33.4687 24.328C33.6403 23.3686 34.3882 22.4508 35.2672 22.2567C35.5326 22.1998 35.7205 21.9584 35.7149 21.6799L35.7113 16.419C35.6999 16.0609 35.3667 15.8007 35.0213 15.8782C33.9846 16.1145 32.973 15.4293 32.5431 14.6017C32.4458 14.4136 32.2532 14.2969 32.0411 14.2969L22.4242 14.2976C21.7577 14.2965 21.7072 15.4441 22.4242 15.4441L22.424 15.4441Z" fill="black" />
            <path d="M16.0534 12.7854H37.0998V26.245L6.59477 26.2421L6.59513 15.1954L7.00195 15.7149C8.24587 17.2936 10.2324 17.8377 12.1634 18.1638C15.2438 18.6837 16.7617 19.6225 19.943 21.1163C20.6703 21.4572 21.3073 21.6243 21.8911 21.3527C22.4717 21.0836 22.7339 20.492 22.9194 19.801C23.2345 18.6502 22.9492 17.5564 22.3416 16.7007C21.7319 15.8432 20.8295 15.1889 19.8485 14.6922L16.0534 12.7854ZM6.80306 1.00905L8.42746 2.83537C9.60635 4.15975 10.3204 4.60514 12.0913 5.21831L18.3876 7.39335C19.2541 7.69239 19.9349 7.84227 20.4426 8.00332C20.9521 8.16761 21.2701 8.32001 21.5651 8.63346L24.4039 11.6379L15.1363 11.6375C15.1811 11.506 15.249 11.3536 15.3637 11.1717C15.7673 10.5224 14.807 9.90953 14.4027 10.5588C14.0488 11.123 13.8569 11.7002 13.972 12.2742C14.0875 12.8481 14.5178 13.2995 15.0834 13.5831L19.3399 15.7203C20.2065 16.1566 20.9672 16.733 21.4217 17.3733C21.875 18.0124 22.0438 18.6736 21.8313 19.4958C21.5716 20.4967 21.0294 20.3536 20.4187 20.0697C17.2736 18.5958 15.5905 17.5733 12.3516 17.0264C10.5034 16.7154 8.8349 16.201 7.8884 14.9972L5.16789 11.5446C4.68681 10.9317 4.3507 10.026 4.00892 9.08775C3.66712 8.14956 3.32533 7.17142 2.65057 6.4306L0.987797 4.60715C0.372066 3.93125 -0.308699 4.88205 0.150714 5.38357L1.81922 7.21025C2.27045 7.70601 2.60656 8.55197 2.94836 9.4851C3.28732 10.4171 3.63195 11.4339 4.28396 12.2586L5.45649 13.7502V26.8187C5.45649 27.1354 5.71053 27.3955 6.02567 27.3926H37.6622C37.9752 27.3955 38.2282 27.1383 38.2314 26.8216L38.2325 12.2118C38.2289 11.8951 37.9759 11.6379 37.6633 11.6379H25.9763L22.3859 7.83869C21.924 7.34906 21.3722 7.09795 20.7853 6.91166C20.2012 6.72358 19.5588 6.58343 18.754 6.30494L12.4616 4.12629C10.7395 3.53398 10.4006 3.3315 9.2718 2.06331L7.64804 0.237733C7.03942 -0.443927 6.35619 0.506511 6.80314 1.00947L6.80306 1.00905Z" fill="black" />
        </svg>
    );
}

export default BeforeListDIcon;