import React from 'react';

function UploadImageIcon() {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_20267_363785)">
                <path d="M29.7783 7.08398H26.9167V4.22232C26.9167 3.45732 26.2933 2.83398 25.5283 2.83398H25.4858C24.7067 2.83398 24.0833 3.45732 24.0833 4.22232V7.08398H21.2358C20.4708 7.08398 19.8475 7.70732 19.8333 8.47232V8.51482C19.8333 9.29398 20.4567 9.91732 21.2358 9.91732H24.0833V12.7648C24.0833 13.5298 24.7067 14.1673 25.4858 14.1532H25.5283C26.2933 14.1532 26.9167 13.5298 26.9167 12.7648V9.91732H29.7783C30.5433 9.91732 31.1667 9.29398 31.1667 8.52898V8.47232C31.1667 7.70732 30.5433 7.08398 29.7783 7.08398ZM22.6667 12.7648V11.334H21.2358C20.485 11.334 19.7767 11.0365 19.2383 10.5123C18.7142 9.97398 18.4167 9.26565 18.4167 8.47232C18.4167 7.96232 18.5583 7.49482 18.7992 7.08398H7.08333C5.525 7.08398 4.25 8.35898 4.25 9.91732V26.9173C4.25 28.4757 5.525 29.7507 7.08333 29.7507H24.0833C25.6417 29.7507 26.9167 28.4757 26.9167 26.9173V15.1873C26.4917 15.4282 26.01 15.584 25.4717 15.584C23.9275 15.5698 22.6667 14.309 22.6667 12.7648ZM22.61 26.9173H8.5C7.91917 26.9173 7.57917 26.2515 7.93333 25.784L10.7383 22.0581C11.0358 21.6615 11.6167 21.6898 11.9 22.0865L14.1667 25.5007L17.8642 20.5707C18.1475 20.2023 18.7 20.1882 18.9833 20.5565L23.1625 25.7698C23.5308 26.2373 23.205 26.9173 22.61 26.9173Z" fill="#B1B5C4" />
            </g>
            <defs>
                <clipPath id="clip0_20267_363785">
                    <rect width="34" height="34" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default UploadImageIcon;