import React from 'react';

function CopyIcon() {
    return (
        <svg width="17.5" height="17.5" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5812 2.39264L16.1089 1.92021C14.5482 0.358649 12.0168 0.361144 10.46 1.92041L8.75592 3.62457C8.94503 3.60369 9.13117 3.56906 9.32422 3.56906C10.1015 3.56906 10.844 3.75811 11.5251 4.08323L12.0741 3.5342C12.7515 2.8544 13.8361 2.87536 14.4949 3.5342L14.9675 4.00677C15.6444 4.68369 15.6285 5.76643 14.9686 6.42647L10.3051 11.0904C9.63211 11.7657 8.5453 11.7536 7.88652 11.0927C7.52152 10.7285 7.28438 10.5463 7.08823 10.1334L5.43359 11.788C5.74049 12.1997 5.84638 12.2814 6.2686 12.7026C7.82063 14.263 10.3579 14.2682 11.919 12.7044L16.5816 8.04132C18.1404 6.48451 18.1428 3.95201 16.5812 2.39264Z" fill="#777E90" />
            <path d="M11.7665 6.26103C10.2059 4.69947 7.67451 4.70196 6.1177 6.26123L1.41672 10.9616C-0.176254 12.5509 -0.102939 15.0865 1.42236 16.6089L1.89209 17.0775C3.44411 18.6379 5.98136 18.6431 7.54254 17.0793L9.63416 14.9869C9.45537 15.0055 9.2748 15.0164 9.09248 15.0164C9.09248 15.0164 9.09248 15.0164 9.09211 15.0164C8.31258 15.0164 7.56295 14.8386 6.88131 14.5124L5.92855 15.4654C5.25556 16.1407 4.16879 16.1287 3.50998 15.4677L3.03406 14.9929C2.36708 14.3259 2.36154 13.2446 3.02852 12.5777L7.73183 7.87499C8.40924 7.19519 9.49372 7.21614 10.1526 7.87499C10.4826 8.20496 10.7729 8.44349 10.9548 8.82507L12.6021 7.17769C12.2971 6.77006 12.2065 6.70106 11.7665 6.26103Z" fill="#777E90" />
        </svg>
    );
}

export default CopyIcon;