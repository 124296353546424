import React from 'react';

function ViewmoreIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.75032 16.25L23.2328 16.25L16.6166 22.8663C16.4984 22.9819 16.4043 23.1198 16.3397 23.272C16.2752 23.4242 16.2415 23.5877 16.2406 23.753C16.2397 23.9183 16.2716 24.0822 16.3344 24.2351C16.3973 24.388 16.4899 24.527 16.6068 24.6439C16.7237 24.7607 16.8626 24.8533 17.0156 24.9161C17.1685 24.9789 17.3324 25.0108 17.4977 25.0099C17.663 25.0089 17.8265 24.9752 17.9787 24.9106C18.1309 24.8461 18.2688 24.752 18.3844 24.6338L27.1344 15.8838C27.3687 15.6493 27.5004 15.3315 27.5004 15C27.5004 14.6685 27.3687 14.3507 27.1344 14.1163L18.3844 5.36625C18.2684 5.24995 18.1306 5.15772 17.9789 5.09487C17.8272 5.03201 17.6645 4.99977 17.5003 5C17.2531 5.00005 17.0115 5.07339 16.806 5.21075C16.6005 5.3481 16.4403 5.54331 16.3457 5.77168C16.2511 6.00006 16.2264 6.25135 16.2746 6.49379C16.3228 6.73623 16.4418 6.95894 16.6166 7.13375L23.2328 13.75L3.75032 13.75C3.4188 13.75 3.10086 13.8817 2.86644 14.1161C2.63201 14.3505 2.50032 14.6685 2.50032 15C2.50032 15.3315 2.63201 15.6495 2.86644 15.8839C3.10086 16.1183 3.4188 16.25 3.75032 16.25Z" fill="#141416" />
        </svg>
    );
}

export default ViewmoreIcon;