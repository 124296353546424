import React from 'react';

function CalenderIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8332 3.33268H14.9998V2.49935C14.9998 1.99935 14.6665 1.66602 14.1665 1.66602C13.6665 1.66602 13.3332 1.99935 13.3332 2.49935V3.33268H6.6665V2.49935C6.6665 1.99935 6.33317 1.66602 5.83317 1.66602C5.33317 1.66602 4.99984 1.99935 4.99984 2.49935V3.33268H4.1665C2.74984 3.33268 1.6665 4.41602 1.6665 5.83268V6.66602H18.3332V5.83268C18.3332 4.41602 17.2498 3.33268 15.8332 3.33268ZM1.6665 15.8327C1.6665 17.2493 2.74984 18.3327 4.1665 18.3327H15.8332C17.2498 18.3327 18.3332 17.2493 18.3332 15.8327V8.33268H1.6665V15.8327ZM14.1665 9.99935C14.6665 9.99935 14.9998 10.3327 14.9998 10.8327C14.9998 11.3327 14.6665 11.666 14.1665 11.666C13.6665 11.666 13.3332 11.3327 13.3332 10.8327C13.3332 10.3327 13.6665 9.99935 14.1665 9.99935ZM14.1665 13.3327C14.6665 13.3327 14.9998 13.666 14.9998 14.166C14.9998 14.666 14.6665 14.9993 14.1665 14.9993C13.6665 14.9993 13.3332 14.666 13.3332 14.166C13.3332 13.666 13.6665 13.3327 14.1665 13.3327ZM9.99984 9.99935C10.4998 9.99935 10.8332 10.3327 10.8332 10.8327C10.8332 11.3327 10.4998 11.666 9.99984 11.666C9.49984 11.666 9.1665 11.3327 9.1665 10.8327C9.1665 10.3327 9.49984 9.99935 9.99984 9.99935ZM9.99984 13.3327C10.4998 13.3327 10.8332 13.666 10.8332 14.166C10.8332 14.666 10.4998 14.9993 9.99984 14.9993C9.49984 14.9993 9.1665 14.666 9.1665 14.166C9.1665 13.666 9.49984 13.3327 9.99984 13.3327ZM5.83317 9.99935C6.33317 9.99935 6.6665 10.3327 6.6665 10.8327C6.6665 11.3327 6.33317 11.666 5.83317 11.666C5.33317 11.666 4.99984 11.3327 4.99984 10.8327C4.99984 10.3327 5.33317 9.99935 5.83317 9.99935ZM5.83317 13.3327C6.33317 13.3327 6.6665 13.666 6.6665 14.166C6.6665 14.666 6.33317 14.9993 5.83317 14.9993C5.33317 14.9993 4.99984 14.666 4.99984 14.166C4.99984 13.666 5.33317 13.3327 5.83317 13.3327Z" fill="#23262F" />
        </svg>
    );
}

export default CalenderIcon;