import React from 'react';

function TelegramShareIcon() {
    return (
        <svg width="17.5" height="14.68" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5688 0.0965025L1.06714 6.07421C0.00921883 6.49912 0.0153392 7.08929 0.873044 7.35246L4.85293 8.59399L14.0612 2.78415C14.4966 2.51923 14.8945 2.66175 14.5675 2.95202L7.10692 9.68513H7.10517L7.10692 9.68601L6.83239 13.7883C7.23457 13.7883 7.41206 13.6038 7.63763 13.3861L9.57075 11.5063L13.5917 14.4764C14.3331 14.8847 14.8656 14.6749 15.0501 13.7901L17.6897 1.35027C17.9598 0.266994 17.2761 -0.223497 16.5688 0.0965025Z" fill="#229ED9" />
        </svg>
    );
}

export default TelegramShareIcon;