import React from 'react';

function AfterListAIcon() {
    return (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M7.78571 5C6.2582 5 5 6.2582 5 7.78571V28.2143C5 29.7418 6.2582 31 7.78571 31H16.1429C16.1429 22.3333 16.1429 13.6667 16.1429 5H7.78571ZM18 5V10.5714H31V7.78571C31 6.2582 29.7418 5 28.2143 5H18ZM18 12.4286V17.0714H31V12.4286H18ZM18 18.9286V31H28.2143C29.7418 31 31 29.7418 31 28.2143V18.9286H18Z" fill="#3772FF"/>
</svg>
    );
}

export default AfterListAIcon;