import React from 'react';

function VerificationIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_20267_363746)">
                <path d="M1.24797 4.94447C1.53597 4.94447 1.72797 4.75247 1.72797 4.46447V1.87247C1.72797 1.63247 1.87197 1.44047 2.11197 1.44047H4.70397C4.99197 1.44047 5.18397 1.24847 5.18397 0.960469C5.18397 0.672469 4.99197 0.480469 4.70397 0.480469H2.11197C1.34397 0.480469 0.767971 1.10447 0.767971 1.87247V4.51247C0.767971 4.75247 0.959971 4.94447 1.24797 4.94447ZM4.70397 19.2965H2.11197C1.87197 19.2965 1.67997 19.1045 1.67997 18.8645V16.2725C1.67997 15.9845 1.48797 15.7925 1.19997 15.7925C0.911971 15.7925 0.719971 15.9845 0.719971 16.2725V18.8645C0.719971 19.6325 1.34397 20.2565 2.11197 20.2565H4.70397C4.99197 20.2565 5.18397 20.0645 5.18397 19.7765C5.18397 19.4885 4.99197 19.2965 4.70397 19.2965ZM22.752 15.7925C22.464 15.7925 22.272 15.9845 22.272 16.2725V18.8645C22.272 19.1045 22.08 19.2965 21.84 19.2965H19.248C18.96 19.2965 18.768 19.4885 18.768 19.7765C18.768 20.0645 18.96 20.2565 19.248 20.2565H21.84C22.608 20.2565 23.232 19.6325 23.232 18.8645V16.2725C23.232 15.9845 23.04 15.7925 22.752 15.7925ZM21.888 0.480469H19.296C19.008 0.480469 18.816 0.672469 18.816 0.960469C18.816 1.24847 19.008 1.44047 19.296 1.44047H21.888C22.128 1.44047 22.32 1.63247 22.32 1.87247V4.51247C22.32 4.80047 22.512 4.99247 22.8 4.99247C23.088 4.99247 23.28 4.80047 23.28 4.51247V1.87247C23.232 1.10447 22.656 0.480469 21.888 0.480469ZM8.44797 18.8165C9.55197 19.7765 10.8 20.2565 12 20.2565C13.248 20.2565 14.448 19.7765 15.552 18.8165C17.808 16.8965 19.296 13.3925 19.296 10.0325C19.296 8.20847 18.864 6.72047 17.952 5.52047C17.952 8.78447 15.264 11.4725 12 11.4725C8.73597 11.4725 6.04797 8.78447 6.04797 5.52047C5.18397 6.72047 4.70397 8.25647 4.70397 10.0325C4.70397 13.3445 6.19197 16.8485 8.44797 18.8165Z" fill="#B1B5C4" />
                <path d="M15.696 19.8725C14.544 20.7365 13.248 21.1685 12 21.1685C10.752 21.1685 9.45602 20.7365 8.30402 19.8245C8.30402 20.8325 7.72802 21.8405 6.67202 22.6085C6.52802 22.7525 6.43202 22.9445 6.48002 23.1365C6.52802 23.3285 6.72002 23.4725 6.91202 23.4725H17.04C17.232 23.4725 17.424 23.3285 17.472 23.1365C17.52 22.9445 17.472 22.7045 17.28 22.6085C16.32 21.8885 15.744 20.8805 15.696 19.8725ZM12 10.5125C14.784 10.5125 16.992 8.25647 16.992 5.52047C16.992 2.73647 14.736 0.480469 12 0.480469C9.26402 0.480469 7.00802 2.73647 7.00802 5.47247C7.00802 8.25647 9.21602 10.5125 12 10.5125ZM9.55202 5.08847C9.74402 4.89647 10.032 4.89647 10.224 5.08847L11.328 6.19247L13.728 3.79247C13.92 3.60047 14.208 3.60047 14.4 3.79247C14.592 3.98447 14.592 4.27247 14.4 4.46447L11.664 7.20047C11.568 7.29647 11.472 7.34447 11.328 7.34447C11.184 7.34447 11.088 7.29647 10.992 7.20047L9.55202 5.76047C9.36002 5.56847 9.36002 5.28047 9.55202 5.08847Z" fill="#B1B5C4" />
            </g>
            <defs>
                <clipPath id="clip0_20267_363746">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default VerificationIcon;