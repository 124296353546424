import React from 'react';

function FacebookShareIcon() {
    return (
        <svg width="8.84" height="17.5" viewBox="0 0 9 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.282258 10.0705H2.20054V17.9677C2.20054 18.1237 2.32688 18.25 2.4828 18.25H5.73531C5.89123 18.25 6.01757 18.1237 6.01757 17.9677V10.1077H8.2228C8.36618 10.1077 8.48682 10.0001 8.50319 9.85768L8.83812 6.95031C8.84732 6.87031 8.82197 6.79021 8.76846 6.7302C8.71489 6.67014 8.63822 6.63576 8.55778 6.63576H6.01768V4.81327C6.01768 4.26389 6.31349 3.9853 6.89698 3.9853C6.98013 3.9853 8.55778 3.9853 8.55778 3.9853C8.7137 3.9853 8.84004 3.8589 8.84004 3.70304V1.03435C8.84004 0.878427 8.7137 0.752089 8.55778 0.752089H6.26895C6.25281 0.751298 6.21696 0.75 6.16412 0.75C5.76698 0.75 4.38657 0.82796 3.29615 1.8311C2.08798 2.94275 2.25592 4.27377 2.29606 4.50454V6.6357H0.282258C0.126339 6.6357 0 6.76204 0 6.91796V9.78819C0 9.9441 0.126339 10.0705 0.282258 10.0705Z" fill="#3b5998" />
        </svg>
    );
}

export default FacebookShareIcon;