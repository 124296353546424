import React from 'react';

function EmailShareIcon() {
    return (
        <svg width="20" height="14.46" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0013 13.4344V1.02051L13.1055 7.22745L20.0013 13.4344Z" fill="#DB4437" />
            <path d="M9.99939 10.0231L8.03061 8.25098L1.13477 14.4579H18.864L11.9682 8.25098L9.99939 10.0231Z" fill="#DB4437" />
            <path d="M18.864 0H1.13477L9.99939 7.97906L18.864 0Z" fill="#DB4437" />
            <path d="M0 1.02051V13.4344L6.89579 7.22745L0 1.02051Z" fill="#DB4437" />
        </svg>
    );
}

export default EmailShareIcon;