import React from 'react';

function WhatsappShareIcon() {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0.5C4.0275 0.5 0 4.5275 0 9.5C0 11.48 0.63 13.3025 1.71 14.7875L0.4725 18.05L4.05 17.015C5.4675 17.96 7.1775 18.5 9 18.5C13.9725 18.5 18 14.4725 18 9.5C18 4.5275 13.9725 0.5 9 0.5ZM13.7925 13.235L12.825 14.18C11.8125 15.1925 9.135 14.09 6.7725 11.705C4.41 9.3425 3.3525 6.665 4.2975 5.675L5.265 4.7075C5.625 4.3475 6.2325 4.3475 6.615 4.7075L8.0325 6.125C8.5275 6.62 8.325 7.475 7.6725 7.6775C7.2225 7.835 6.9075 8.33 7.065 8.78C7.3125 9.8375 8.685 11.1425 9.6975 11.39C10.1475 11.5025 10.665 11.2325 10.8 10.7825C11.0025 10.13 11.8575 9.9275 12.3525 10.4225L13.77 11.84C14.13 12.2225 14.13 12.83 13.7925 13.235Z" fill="#25D366" />
        </svg>
    );
}

export default WhatsappShareIcon;