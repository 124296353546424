import React from 'react';

function PreviewIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0001 4.00586C6.06886 4.00586 2.40634 6.12462 0.437607 9.53088C0.268845 9.81835 0.268845 10.1809 0.437607 10.4684C2.40634 13.8746 6.06886 15.9934 10.0001 15.9934C13.9313 15.9934 17.5939 13.8746 19.5626 10.4684C19.7314 10.1809 19.7314 9.81835 19.5626 9.53088C17.5939 6.12462 13.9313 4.00586 10.0001 4.00586ZM10.0001 13.4684C8.0876 13.4684 6.53136 11.9121 6.53136 9.99963C6.53136 8.08713 8.0876 6.53088 10.0001 6.53088C11.9126 6.53088 13.4688 8.08713 13.4688 9.99963C13.4688 11.9121 11.9126 13.4684 10.0001 13.4684Z" fill="#353945" />
            <path d="M10 11.5937C10.8802 11.5937 11.5937 10.8802 11.5937 10C11.5937 9.1198 10.8802 8.40625 10 8.40625C9.1198 8.40625 8.40625 9.1198 8.40625 10C8.40625 10.8802 9.1198 11.5937 10 11.5937Z" fill="#353945" />
        </svg>
    );
}

export default PreviewIcon;