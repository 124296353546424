import React from 'react';

function IssueVerifiedIcon() {
    return (
        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M52.9303 11.5541C56.1633 6.27079 63.8377 6.27079 67.0706 11.5541V11.5541C69.3742 15.3185 74.2234 16.6178 78.1005 14.5095V14.5095C83.542 11.5505 90.1882 15.3877 90.3464 21.5797V21.5797C90.4591 25.9915 94.009 29.5414 98.4208 29.6541V29.6541C104.613 29.8123 108.45 36.4585 105.491 41.9V41.9C103.383 45.7771 104.682 50.6263 108.446 52.9299V52.9299C113.73 56.1628 113.73 63.8372 108.446 67.0701V67.0701C104.682 69.3737 103.383 74.2229 105.491 78.1V78.1C108.45 83.5415 104.613 90.1877 98.4208 90.3459V90.3459C94.009 90.4586 90.4591 94.0085 90.3464 98.4203V98.4203C90.1882 104.612 83.542 108.449 78.1005 105.49V105.49C74.2234 103.382 69.3742 104.681 67.0706 108.446V108.446C63.8377 113.729 56.1633 113.729 52.9303 108.446V108.446C50.6268 104.681 45.7776 103.382 41.9004 105.49V105.49C36.459 108.449 29.8128 104.612 29.6546 98.4203V98.4203C29.5419 94.0085 25.992 90.4586 21.5801 90.3459V90.3459C15.3882 90.1877 11.551 83.5415 14.51 78.1V78.1C16.6183 74.2229 15.319 69.3737 11.5545 67.0701V67.0701C6.27128 63.8372 6.27128 56.1628 11.5545 52.9299V52.9299C15.319 50.6263 16.6183 45.7771 14.51 41.9V41.9C11.551 36.4585 15.3882 29.8123 21.5801 29.6541V29.6541C25.992 29.5414 29.5419 25.9915 29.6546 21.5797V21.5797C29.8128 15.3877 36.459 11.5505 41.9004 14.5095V14.5095C45.7776 16.6178 50.6268 15.3185 52.9303 11.5541V11.5541Z" fill="#27AE60" />
            <path d="M54.6422 77L39.0005 61.3078L43.9441 56.342L54.4891 66.9204L76.9056 43L82.0005 47.8103L54.6422 77Z" fill="white" />
        </svg>
    );
}

export default IssueVerifiedIcon;