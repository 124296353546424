import React from 'react';

function LogoutIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_20311_451)">
                <path d="M21 0H10C9.20508 0.00237409 8.4434 0.319207 7.8813 0.881302C7.31921 1.4434 7.00237 2.20508 7 3V9H16C16.7956 9 17.5587 9.31607 18.1213 9.87868C18.6839 10.4413 19 11.2044 19 12C19 12.7956 18.6839 13.5587 18.1213 14.1213C17.5587 14.6839 16.7956 15 16 15H7V21C7.00237 21.7949 7.31921 22.5566 7.8813 23.1187C8.4434 23.6808 9.20508 23.9976 10 24H21C21.7949 23.9976 22.5566 23.6808 23.1187 23.1187C23.6808 22.5566 23.9976 21.7949 24 21V3C23.9976 2.20508 23.6808 1.4434 23.1187 0.881302C22.5566 0.319207 21.7949 0.00237409 21 0Z" fill="#B1B5C4" />
                <path d="M3.40997 13.0002H16C16.2652 13.0002 16.5195 12.8948 16.7071 12.7073C16.8946 12.5198 17 12.2654 17 12.0002C17 11.735 16.8946 11.4806 16.7071 11.2931C16.5195 11.1055 16.2652 11.0002 16 11.0002H3.40997L4.70997 9.71019C4.89827 9.52188 5.00406 9.26649 5.00406 9.00019C5.00406 8.73388 4.89827 8.47849 4.70997 8.29019C4.52166 8.10188 4.26627 7.99609 3.99997 7.99609C3.73366 7.99609 3.47827 8.10188 3.28997 8.29019L0.289966 11.2902C0.109339 11.4825 0.00878906 11.7364 0.00878906 12.0002C0.00878906 12.264 0.109339 12.5179 0.289966 12.7102L3.28997 15.7102C3.48145 15.8924 3.73565 15.994 3.99997 15.994C4.26428 15.994 4.51848 15.8924 4.70997 15.7102C4.89732 15.5214 5.00245 15.2662 5.00245 15.0002C5.00245 14.7342 4.89732 14.479 4.70997 14.2902L3.40997 13.0002Z" fill="#B1B5C4" />
            </g>
            <defs>
                <clipPath id="clip0_20311_451">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default LogoutIcon;