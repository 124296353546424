import React from 'react';

function AfterListAIcon() {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.3699 8.12013C30.1964 6.82013 25.3995 3.45766 23.2475 1.66409C22.4854 1.03645 21.4092 1.03645 20.6472 1.66409C18.4953 3.45726 13.6533 6.81973 6.47992 8.12013C5.44884 8.34436 4.73143 9.28571 4.86593 10.3618C5.67277 17.2659 7.95949 22.6012 10.6046 26.6362C11.053 26.1877 11.5462 25.8293 12.1288 25.5601V24.6635C12.1288 19.8216 16.0742 15.9211 20.8712 15.9211H22.9337C27.7757 15.9211 31.6762 19.8665 31.6762 24.6635V25.5601C32.2141 25.8291 32.7522 26.1877 33.2004 26.6362C35.8906 22.646 38.1769 17.3109 38.9837 10.3618C39.1185 9.28571 38.4011 8.2993 37.37 8.12013H37.3699Z" fill="white" />
            <path d="M29.3897 28.1148H28.6723V24.7075C28.6723 21.5693 26.1167 19.0137 22.9784 19.0137H20.9159C17.778 19.0137 15.2224 21.5693 15.2224 24.7075V28.1148H14.505C13.1152 28.1148 11.9493 29.2356 11.9493 30.6704V41.251C11.9493 42.6408 13.0702 43.8067 14.505 43.8067H29.3896C30.7793 43.8067 31.9452 42.6858 31.9452 41.251L31.9449 30.6256C31.9002 29.2355 30.7793 28.1147 29.3896 28.1147L29.3897 28.1148ZM18.6298 24.7075C18.6298 23.4522 19.6608 22.4211 20.9162 22.4211H22.9787C24.234 22.4211 25.2651 23.4522 25.2651 24.7075V28.1148H18.6298L18.6298 24.7075ZM26.2066 34.6605L21.9922 38.8749C21.768 39.0991 21.4543 39.2336 21.1403 39.2336C20.8263 39.2336 20.5127 39.0991 20.2884 38.8749L17.6434 36.2298C17.1949 35.7813 17.1949 35.0192 17.6434 34.5261C18.0918 34.0776 18.8539 34.0776 19.3471 34.5261L21.1403 36.3192L24.5027 32.9567C24.9512 32.5083 25.7133 32.5083 26.2065 32.9567C26.6997 33.4499 26.6997 34.2121 26.2065 34.6605H26.2066Z" fill="white" />
        </svg>
    );
}

export default AfterListAIcon;