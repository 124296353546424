import React from 'react';

function TwitterShareIcon() {
    return (
        <svg width="21.37" height="17.5" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.6402 2.76962C21.3044 2.91856 20.9592 3.04425 20.6064 3.1463C21.0241 2.67399 21.3425 2.11825 21.5369 1.51011C21.5804 1.37379 21.5353 1.22458 21.4232 1.13543C21.3113 1.04621 21.1558 1.03552 21.0326 1.10854C20.2835 1.55286 19.4752 1.87217 18.6278 2.05888C17.7741 1.22472 16.6146 0.75 15.416 0.75C12.8859 0.75 10.8274 2.80837 10.8274 5.33844C10.8274 5.5377 10.8401 5.73586 10.865 5.93127C7.72537 5.65561 4.8065 4.11243 2.80211 1.65373C2.73068 1.5661 2.62064 1.51886 2.50797 1.5279C2.39524 1.53672 2.29395 1.6003 2.237 1.698C1.83047 2.39557 1.61555 3.19339 1.61555 4.00514C1.61555 5.11076 2.01029 6.15977 2.70758 6.97945C2.49556 6.90602 2.28981 6.81425 2.09344 6.70524C1.98802 6.64656 1.85936 6.64746 1.75462 6.70751C1.64982 6.76757 1.58411 6.87803 1.58135 6.99876C1.58087 7.0191 1.58087 7.03944 1.58087 7.06006C1.58087 8.71038 2.46908 10.1962 3.82706 11.006C3.71039 10.9944 3.5938 10.9775 3.47796 10.9553C3.35854 10.9325 3.23574 10.9744 3.15521 11.0654C3.07453 11.1565 3.04785 11.2833 3.08502 11.3992C3.58766 12.9685 4.88179 14.1228 6.44627 14.4747C5.1487 15.2874 3.66482 15.7131 2.10709 15.7131C1.78206 15.7131 1.45517 15.694 1.13524 15.6562C0.976309 15.6373 0.824343 15.7311 0.770217 15.8822C0.716091 16.0335 0.773458 16.202 0.908669 16.2887C2.90981 17.5718 5.22371 18.25 7.60008 18.25C12.2717 18.25 15.1942 16.047 16.8231 14.199C18.8544 11.8946 20.0194 8.84442 20.0194 5.83067C20.0194 5.70477 20.0175 5.57762 20.0136 5.45089C20.815 4.8471 21.505 4.11636 22.0665 3.27648C22.1518 3.14892 22.1425 2.98034 22.0437 2.86291C21.945 2.74542 21.7806 2.70743 21.6402 2.76962Z" fill="#00acee" />
    </svg>
    );
}

export default TwitterShareIcon;