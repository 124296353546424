import React from 'react';

function LinksDIcon() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.6838 3H9.7392V29.0946H29.904V8.33689L24.6838 3ZM20.3548 23.4892H12.7638V21.8888H20.3548V23.4892ZM23.7949 20.5251H12.7639V18.9238H23.7949V20.5251ZM23.7949 17.2334H12.7639V15.6319H23.7949V17.2334ZM23.6163 9.52391V4.42369L28.7168 9.52415L23.6163 9.52391Z" fill="#3772FF" />
            <path d="M8.31958 5.76172H6V33.0003H27.744V30.6201H8.31964L8.31958 5.76172Z" fill="#3772FF" />
        </svg>
    );
}

export default LinksDIcon;