import React from 'react';

function AfterListBIcon() {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.94 9.81283H15.6713C16.119 9.81283 16.4175 9.51432 16.4175 9.06657C16.4175 8.61882 16.119 8.32031 15.6713 8.32031H11.94C11.4922 8.32031 11.1937 8.61882 11.1937 9.06657C11.1937 9.51432 11.4922 9.81283 11.94 9.81283Z" fill="white" />
            <path d="M3.73112 18.0218H6.71615C7.16391 18.0218 7.46241 17.6487 7.46241 17.2756C7.46241 16.8278 7.16391 16.5293 6.71615 16.5293H3.73112C3.28337 16.5293 2.98486 16.9024 2.98486 17.2756C2.98486 17.7233 3.28337 18.0218 3.73112 18.0218Z" fill="white" />
            <path d="M6.71615 26.9766H3.73112C3.28337 26.9766 2.98486 27.3497 2.98486 27.7228C2.98486 28.1706 3.28337 28.4691 3.73112 28.4691H6.71615C7.16391 28.4691 7.46241 28.0959 7.46241 27.7228C7.46241 27.2751 7.16391 26.9766 6.71615 26.9766Z" fill="white" />
            <path d="M15.6713 35.1855H11.94C11.4922 35.1855 11.1937 35.484 11.1937 35.9318C11.1937 36.3796 11.4922 36.6781 11.94 36.6781H15.6713C16.119 36.6781 16.4175 36.3796 16.4175 35.9318C16.4175 35.484 16.119 35.1855 15.6713 35.1855Z" fill="white" />
            <path d="M38.0589 21.7515H38.7305C38.3574 16.1545 33.8799 11.6774 28.358 11.3789V12.0505C28.358 12.4983 28.0595 12.7968 27.6117 12.7968C27.164 12.7968 26.8655 12.4983 26.8655 12.0505V11.3789C21.2685 11.752 16.7914 16.2296 16.4929 21.7515H17.1646C17.6123 21.7515 17.9108 22.05 17.9108 22.4977C17.9108 22.9455 17.6123 23.244 17.1646 23.244H16.4929C16.866 28.8409 21.3436 33.318 26.8655 33.6165V32.9449C26.8655 32.4971 27.164 32.1986 27.6117 32.1986C28.0595 32.1986 28.358 32.4971 28.358 32.9449V33.6165C33.9549 33.2434 38.432 28.7659 38.7305 23.244H38.0589C37.6112 23.244 37.3127 22.9455 37.3127 22.4977C37.3127 22.05 37.6112 21.7515 38.0589 21.7515ZM34.3276 23.244H30.5217C30.2232 24.5126 29.0292 25.4827 27.6113 25.4827C25.9695 25.4827 24.6263 24.1395 24.6263 22.4977C24.6263 21.0798 25.5964 19.9604 26.865 19.5873V15.0351C26.865 14.5874 27.1635 14.2889 27.6113 14.2889C28.0591 14.2889 28.3576 14.5874 28.3576 15.0351V19.5873C29.4023 19.8858 30.2232 20.7067 30.5217 21.7515H34.3276C34.7754 21.7515 35.0739 22.05 35.0739 22.4977C35.0739 22.9455 34.7754 23.244 34.3276 23.244Z" fill="white" />
            <path d="M43.2829 21.6782C42.8352 13.8425 35.5965 6.90232 27.7608 6.82812C24.4026 6.82812 21.1937 7.79826 18.5072 9.73853C16.0445 11.5295 13.358 12.7236 10.5222 13.3952L8.05958 13.9922C7.76108 14.0668 7.46257 14.3653 7.46257 14.7385C7.46257 15.1116 7.68645 15.4101 8.05958 15.4847L12.3879 16.6041C12.5371 16.6787 12.6864 16.7533 12.8356 16.9026C12.9849 17.0519 12.9103 17.2757 12.9103 17.425C12.8356 17.6489 12.6864 17.7981 12.4625 17.8727L0.52238 21.8279C0.223877 21.9025 0 22.201 0 22.4995C0 22.798 0.223877 23.0965 0.52238 23.1712L12.3874 27.1263C12.6113 27.201 12.7606 27.3502 12.8352 27.5741C12.9098 27.7233 12.9098 27.9472 12.7606 28.0965C12.686 28.2457 12.5367 28.395 12.3874 28.395L8.05915 29.5144C7.76065 29.589 7.46215 29.8875 7.46215 30.2606C7.46215 30.6337 7.68603 30.9322 8.05915 31.0069L10.6711 31.6039C13.5068 32.2755 16.1187 33.4695 18.4321 35.1859C21.1187 37.1262 24.3276 38.1709 27.6107 38.1709C31.939 38.1709 35.9688 36.4545 38.9538 33.3203C41.9388 30.1114 43.506 26.0069 43.2821 21.6786L43.2829 21.6782ZM27.6115 35.1859C20.5967 35.1859 14.9251 29.5144 14.9251 22.4995C14.9251 15.4847 20.5967 9.81315 27.6115 9.81315C34.6263 9.81315 40.2979 15.4847 40.2979 22.4995C40.2979 29.5144 34.6263 35.1859 27.6115 35.1859Z" fill="white" />
        </svg>
    );
}

export default AfterListBIcon;