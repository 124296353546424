import React from 'react';

function UpyoWalletIocn({className}) {
    return (
        <svg width="87" className={className} height="82" viewBox="0 0 87 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M61.8319 0C75.7093 0 87 11.4575 87 25.5448V56.4552C87 70.5425 76.7093 82 62.8319 82C61.2311 82 59.9319 80.6812 59.9319 79.0561C59.9319 77.4311 61.2311 76.1123 62.8319 76.1123C73.5116 76.1123 81.2 67.2964 81.2 56.4552V31.7857H66.9165C62.2263 31.7897 58.4021 35.6677 58.3983 40.4328C58.4021 45.1979 62.2263 49.076 66.9165 49.0799H72.8519C74.4527 49.0799 75.7519 50.3987 75.7519 52.0237C75.7519 53.6487 74.4527 54.9676 72.8519 54.9676H66.9165C59.0247 54.9637 52.6021 48.444 52.5983 40.4328C52.6021 32.4216 59.0247 25.902 66.9165 25.898H81.2V25.5448C81.2 14.7036 72.5116 5.8877 61.8319 5.8877H25.1643C16.5571 5.8877 9.33413 11.6498 6.81693 19.5432H45.0428C46.6436 19.5432 47.9428 20.8621 47.9428 22.4871C47.9428 24.116 46.6436 25.431 45.0428 25.431H5.8116L5.8 25.5448V56.4552C5.8 67.2964 14.4845 76.1123 25.1643 76.1123H23.6121C25.2129 76.1123 26.5121 77.4311 26.5121 79.0561C26.5121 80.6812 25.2129 82 23.6121 82H25.1643C11.2868 82 0 70.5425 0 56.4552V25.5448C0 11.4575 11.2868 0 25.1643 0H61.8319ZM68.8638 37.2236C70.4646 37.2236 71.7638 38.5425 71.7638 40.1675C71.7638 41.7925 70.4646 43.1113 68.8638 43.1113H67.5452C65.9445 43.1113 64.6453 41.7925 64.6453 40.1675C64.6453 38.5425 65.9445 37.2236 67.5452 37.2236H68.8638Z" fill="#FCFCFD" />
            <path d="M38.724 79.6782V79.7394C38.724 80.3455 38.724 80.9515 38.724 81.5574C38.724 81.7263 38.6296 81.8555 38.4739 81.8965C38.4375 81.9052 38.4002 81.9094 38.3628 81.9089C38.1452 81.9101 37.9276 81.9089 37.7102 81.9089C37.4871 81.9089 37.3473 81.7706 37.3473 81.5505C37.3473 80.6058 37.3473 79.6611 37.3473 78.7165C37.3473 78.5941 37.3885 78.4939 37.4899 78.4258C37.5352 78.3954 37.5929 78.3813 37.646 78.3646C37.6725 78.3597 37.6996 78.3586 37.7265 78.3613C38.631 78.3613 39.5356 78.3613 40.4403 78.3613C40.6356 78.3613 40.8299 78.3505 41.0183 78.2914C41.345 78.1894 41.5517 77.9754 41.618 77.6403C41.6696 77.3794 41.6624 77.1199 41.5562 76.8707C41.4461 76.6104 41.2416 76.4595 40.9724 76.3854C40.7941 76.3363 40.6119 76.3269 40.4285 76.3269C39.524 76.3269 38.6195 76.3269 37.7149 76.3269C37.5914 76.3269 37.4918 76.2891 37.4162 76.1931C37.3744 76.1436 37.3506 76.0816 37.3487 76.017C37.3475 75.7825 37.3475 75.5478 37.3487 75.3131C37.3487 75.1671 37.4904 75.0047 37.6614 75.002C37.6734 75.002 37.6853 75.001 37.6974 75.001C38.6769 75.001 39.6566 74.9974 40.636 75.0039C41.1095 75.0071 41.5613 75.1134 41.9685 75.3635C42.4982 75.6888 42.837 76.1519 42.9634 76.7587C43.0591 77.2183 43.0486 77.6766 42.9128 78.1282C42.7456 78.6824 42.3942 79.0912 41.8864 79.3696C41.5599 79.5485 41.205 79.637 40.8349 79.6623C40.6556 79.6747 40.4753 79.6758 40.2953 79.6764C39.7928 79.6782 39.2904 79.6764 38.7877 79.6764L38.724 79.6782Z" fill="#FCFCFD" />
            <path d="M53.1713 75.6807C53.1713 75.789 53.1713 75.8975 53.1713 76.0058C53.1713 76.2004 53.0781 76.3163 52.8844 76.3585C52.615 76.4141 52.3613 76.528 52.1415 76.692C51.716 77.0137 51.4516 77.4353 51.3528 77.9566C51.2787 78.3471 51.2816 78.7379 51.381 79.1228C51.5469 79.7641 51.9286 80.232 52.5503 80.4937C52.6618 80.5366 52.7763 80.5717 52.8928 80.5988C53.0781 80.6477 53.1709 80.7601 53.1711 80.9508C53.1711 81.1706 53.1711 81.3903 53.1711 81.6101C53.1711 81.8483 52.9892 81.9913 52.7512 81.9489C52.3739 81.8851 52.0105 81.7574 51.677 81.5713C51.276 81.3419 50.9245 81.0364 50.6428 80.6722C50.2869 80.2207 50.0569 79.6845 49.9759 79.1173C49.8847 78.5084 49.9153 77.9065 50.102 77.3166C50.2667 76.797 50.5492 76.3475 50.9318 75.9587C51.2112 75.6733 51.5416 75.4418 51.9062 75.2758C52.194 75.1449 52.4989 75.0543 52.8119 75.0067C52.8562 74.9999 52.9015 75.0027 52.9447 75.0148C52.9878 75.0269 53.0278 75.0481 53.0619 75.077C53.096 75.1059 53.1235 75.1417 53.1423 75.182C53.1612 75.2223 53.171 75.2662 53.1711 75.3107C53.1742 75.4331 53.1711 75.5573 53.1711 75.6805L53.1713 75.6807Z" fill="#FCFCFD" />
            <path d="M53.7562 81.2745C53.7562 81.1662 53.7562 81.0577 53.7562 80.9492C53.7562 80.7591 53.8492 80.6412 54.0363 80.6004C54.4146 80.5222 54.7606 80.3335 55.0296 80.0586C55.276 79.8069 55.4515 79.4956 55.5385 79.1556C55.6106 78.8745 55.6371 78.5838 55.6169 78.2944C55.5963 77.9617 55.522 77.6417 55.3581 77.3486C55.1407 76.9586 54.8324 76.661 54.4182 76.4813C54.2877 76.4246 54.1465 76.3905 54.0085 76.3518C53.857 76.3093 53.7588 76.1949 53.7572 76.0387C53.7547 75.7951 53.7543 75.5516 53.7572 75.308C53.7584 75.2637 53.7691 75.2201 53.7886 75.1802C53.8082 75.1402 53.8361 75.1049 53.8705 75.0765C53.9049 75.0481 53.945 75.0273 53.9881 75.0155C54.0313 75.0037 54.0765 75.0011 54.1207 75.008C54.8978 75.1338 55.557 75.4771 56.0877 76.057C56.5108 76.5135 56.7988 77.0766 56.9203 77.6845C56.9985 78.0755 57.0193 78.4756 56.9821 78.8725C56.9385 79.3604 56.8135 79.8261 56.5646 80.2508C56.05 81.1289 55.3065 81.7092 54.2939 81.9287C54.2269 81.9445 54.1585 81.9532 54.0897 81.9546C53.8929 81.9546 53.7603 81.8224 53.7574 81.627C53.7545 81.5089 53.7562 81.3916 53.7562 81.2745Z" fill="#FCFCFD" />
            <path d="M45.6922 80.2885C45.6922 79.8459 45.6922 79.4032 45.6922 78.9606C45.6912 78.8756 45.7175 78.7926 45.7674 78.7234C46.5163 77.6554 47.2649 76.5871 48.0132 75.5185C48.097 75.399 48.1812 75.2799 48.2644 75.1601C48.2965 75.1096 48.3415 75.0684 48.3948 75.0405C48.4481 75.0127 48.5078 74.9992 48.568 75.0014C48.8546 75.0037 49.1411 75.0033 49.4277 75.0014C49.6981 75.0002 49.8233 75.2894 49.7338 75.4667C49.7192 75.4944 49.7027 75.5209 49.6844 75.5463C48.9727 76.5641 48.2609 77.5818 47.5492 78.5994C47.4058 78.8048 47.2617 79.0104 47.1176 79.2144C47.0968 79.2431 47.0862 79.2778 47.0873 79.3131C47.0873 80.0853 47.0856 80.8578 47.0896 81.6301C47.0896 81.8089 46.9595 81.9505 46.8244 81.9768C46.7979 81.9815 46.771 81.9836 46.7441 81.9831C46.5115 81.9831 46.2789 81.978 46.0465 81.985C45.8579 81.9907 45.6887 81.832 45.691 81.6296C45.6955 81.1827 45.6922 80.7356 45.6922 80.2885Z" fill="#FCFCFD" />
            <path d="M36.1019 77.3011C36.1019 77.8314 36.1019 78.3617 36.1019 78.8921C36.1019 79.2121 36.0747 79.5295 35.9989 79.8416C35.8548 80.4343 35.5559 80.9373 35.0932 81.341C34.7356 81.6541 34.3035 81.8717 33.8375 81.9733C33.7494 81.9937 33.6633 82.0141 33.5725 81.9835C33.4507 81.9427 33.3549 81.8283 33.3531 81.7008C33.3496 81.4471 33.3488 81.1929 33.3531 80.939C33.3552 80.788 33.4492 80.6761 33.6086 80.6263C33.7667 80.5799 33.9168 80.5096 34.0534 80.418C34.3636 80.2028 34.5514 79.9069 34.6409 79.5458C34.6891 79.3385 34.7117 79.1262 34.7082 78.9135C34.7082 77.7341 34.7082 76.5548 34.7082 75.3755C34.7082 75.2531 34.7414 75.1485 34.8416 75.0696C34.8916 75.0283 34.9544 75.0052 35.0195 75.0041C35.2774 75.0018 35.5356 74.999 35.7933 75.0041C35.958 75.0076 36.0897 75.1436 36.1021 75.308C36.1041 75.3347 36.1048 75.3614 36.1048 75.3882C36.103 76.0262 36.102 76.6638 36.1019 77.3011Z" fill="#FCFCFD" />
            <path d="M31.3978 77.2337C31.3978 77.8368 31.3938 78.44 31.3992 79.0429C31.4023 79.3846 31.4737 79.713 31.6598 80.0069C31.8554 80.3158 32.1277 80.5275 32.4865 80.6246C32.6549 80.6701 32.7523 80.7941 32.7541 80.9713C32.7562 81.2025 32.7562 81.4342 32.7541 81.6665C32.7517 81.8921 32.5689 82.0377 32.3455 81.9914C31.4424 81.8038 30.7775 81.301 30.3462 80.4965C30.1783 80.183 30.086 79.8437 30.0375 79.4927C30.0099 79.2717 29.9975 79.049 30.0004 78.8263C30.0004 77.6736 30.0004 76.5209 30.0004 75.3683C30.0004 75.2439 30.0397 75.1439 30.1402 75.0656C30.1869 75.0273 30.2453 75.0057 30.3059 75.0044C30.5653 75.0024 30.8251 74.9991 31.0842 75.0044C31.2695 75.0087 31.3963 75.1605 31.3965 75.361C31.3965 75.7117 31.3965 76.0622 31.3965 76.4127V77.2324L31.3978 77.2337Z" fill="#FCFCFD" />
            <path d="M43.7874 75.002C43.9299 75.002 44.0726 75.0035 44.2148 75.002C44.2743 75 44.3333 75.0134 44.3861 75.0408C44.4388 75.0682 44.4834 75.1088 44.5156 75.1585C44.9166 75.7275 45.3173 76.2975 45.7177 76.8686C45.8293 77.0273 45.7953 77.2533 45.6461 77.3537C45.5943 77.39 45.5325 77.4097 45.4691 77.4102C45.1708 77.4102 44.8721 77.4112 44.574 77.4102C44.5181 77.4107 44.4629 77.3973 44.4136 77.3712C44.3642 77.3451 44.3223 77.3072 44.2916 77.2609C43.886 76.6853 43.4818 76.1089 43.0787 75.5318C42.9389 75.3311 43.0441 75.0592 43.2759 75.0098C43.3054 75.0045 43.3353 75.0023 43.3652 75.0033C43.5056 75.0018 43.6466 75.002 43.7874 75.002Z" fill="#FCFCFD" />
        </svg>
    );
}

export default UpyoWalletIocn;