import React from 'react';

function LinkedinShareIcon() {
    return (
        <svg width="17.5" height="17.5" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.33112 6.37305H0.810224C0.653961 6.37305 0.527344 6.49972 0.527344 6.65593V17.9671C0.527344 18.1233 0.653961 18.2499 0.810224 18.2499H4.33112C4.48739 18.2499 4.614 18.1233 4.614 17.9671V6.65593C4.614 6.49972 4.48739 6.37305 4.33112 6.37305Z" fill="#0072b1" />
            <path d="M2.57335 0.75C1.29224 0.75 0.25 1.79111 0.25 3.07081C0.25 4.35107 1.29224 5.39258 2.57335 5.39258C3.85344 5.39258 4.89484 4.35101 4.89484 3.07081C4.8949 1.79111 3.85344 0.75 2.57335 0.75Z" fill="#0072b1" />
            <path d="M13.287 6.0918C11.8729 6.0918 10.8275 6.69971 10.1935 7.39044V6.6558C10.1935 6.4996 10.0669 6.37292 9.91062 6.37292H6.53874C6.38248 6.37292 6.25586 6.4996 6.25586 6.6558V17.9669C6.25586 18.1232 6.38248 18.2498 6.53874 18.2498H10.0519C10.2082 18.2498 10.3348 18.1232 10.3348 17.9669V12.3706C10.3348 10.4847 10.8471 9.75001 12.1617 9.75001C13.5934 9.75001 13.7072 10.9278 13.7072 12.4676V17.967C13.7072 18.1233 13.8338 18.2499 13.99 18.2499H17.5045C17.6608 18.2499 17.7874 18.1233 17.7874 17.967V11.7626C17.7874 8.95845 17.2527 6.0918 13.287 6.0918Z" fill="#0072b1" />
        </svg>
    );
}

export default LinkedinShareIcon;