import React from 'react';

function CollapseIcon({className}) {
    return (
        <svg width="30" className={className} height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="28" height="28" rx="14" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.2589 12.2411C19.7707 11.753 18.9793 11.753 18.4911 12.2411L15 15.7322L11.5089 12.2411C11.0207 11.753 10.2293 11.753 9.74112 12.2411C9.25296 12.7293 9.25296 13.5207 9.74112 14.0089L14.1161 18.3839C14.6043 18.872 15.3957 18.872 15.8839 18.3839L20.2589 14.0089C20.747 13.5207 20.747 12.7293 20.2589 12.2411Z" fill="#777E91" />
            <rect x="1" y="1" width="28" height="28" rx="14" stroke="#E6E8EC" strokeWidth="2" />
        </svg>
    );
}

export default CollapseIcon;