import React from 'react';

function LinksAIcon() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.9375 11.4375C29.0833 11.5833 29.2292 11.7708 29.375 12H22V4.625C22.2292 4.77083 22.4167 4.91667 22.5625 5.0625L28.9375 11.4375ZM21.5 14H30V30.5C30 30.9167 29.8542 31.2708 29.5625 31.5625C29.2708 31.8542 28.9167 32 28.5 32H7.5C7.08333 32 6.72917 31.8542 6.4375 31.5625C6.14583 31.2708 6 30.9167 6 30.5V5.5C6 5.08333 6.14583 4.72917 6.4375 4.4375C6.72917 4.14583 7.08333 4 7.5 4H20V12.5C20 12.9167 20.1458 13.2708 20.4375 13.5625C20.7292 13.8542 21.0833 14 21.5 14ZM24 25.5V24.5C24 24.3542 23.9531 24.2344 23.8594 24.1406C23.7656 24.0469 23.6458 24 23.5 24H12.5C12.3542 24 12.2344 24.0469 12.1406 24.1406C12.0469 24.2344 12 24.3542 12 24.5V25.5C12 25.6458 12.0469 25.7656 12.1406 25.8594C12.2344 25.9531 12.3542 26 12.5 26H23.5C23.6458 26 23.7656 25.9531 23.8594 25.8594C23.9531 25.7656 24 25.6458 24 25.5ZM24 21.5V20.5C24 20.3542 23.9531 20.2344 23.8594 20.1406C23.7656 20.0469 23.6458 20 23.5 20H12.5C12.3542 20 12.2344 20.0469 12.1406 20.1406C12.0469 20.2344 12 20.3542 12 20.5V21.5C12 21.6458 12.0469 21.7656 12.1406 21.8594C12.2344 21.9531 12.3542 22 12.5 22H23.5C23.6458 22 23.7656 21.9531 23.8594 21.8594C23.9531 21.7656 24 21.6458 24 21.5ZM24 17.5V16.5C24 16.3542 23.9531 16.2344 23.8594 16.1406C23.7656 16.0469 23.6458 16 23.5 16H12.5C12.3542 16 12.2344 16.0469 12.1406 16.1406C12.0469 16.2344 12 16.3542 12 16.5V17.5C12 17.6458 12.0469 17.7656 12.1406 17.8594C12.2344 17.9531 12.3542 18 12.5 18H23.5C23.6458 18 23.7656 17.9531 23.8594 17.8594C23.9531 17.7656 24 17.6458 24 17.5Z" fill="#3772FF" />
        </svg>
    );
}

export default LinksAIcon;