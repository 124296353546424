import React from 'react';

function LinksCIcon() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 4C6.57437 4 4 6.57437 4 9.75V21.25C4 24.4256 6.57437 27 9.75 27H21.25C24.4256 27 27 24.4256 27 21.25V9.75C27 6.57437 24.4256 4 21.25 4H9.75ZM15.5 14.35C13.5946 14.35 12.05 12.8054 12.05 10.9C12.05 8.99461 13.5946 7.45 15.5 7.45C17.4054 7.45 18.95 8.99461 18.95 10.9C18.95 12.8054 17.4054 14.35 15.5 14.35ZM11.4747 15.7875H19.525C20.9539 15.7875 22.1125 16.9457 22.1125 18.3749V18.95C22.1125 19.8719 21.7182 20.9876 20.6396 21.8542C19.5731 22.7112 17.9181 23.2625 15.4952 23.2625C13.0688 23.2625 11.4157 22.7001 10.3524 21.8376C9.28034 20.9678 8.8875 19.8544 8.8875 18.95V18.3739C8.8875 16.9445 10.0463 15.7875 11.4747 15.7875Z" fill="#3772FF" />
            <path d="M10 29.6842C11.0562 31.0906 12.7378 32 14.6319 32H21.5791C27.3345 32 32 27.3344 32 21.5792V14.6319C32 12.7378 31.0904 11.0562 29.6843 10V21.5792C29.6843 26.0555 26.0555 29.6842 21.5791 29.6842H10Z" fill="#3772FF" />
        </svg>
    );
}

export default LinksCIcon;