import React from 'react';

function DIDsIcon() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 33H27C27.7956 33 28.5587 32.6839 29.1213 32.1213C29.6839 31.5587 30 30.7956 30 30V11.7426C30 10.947 29.6839 10.1839 29.1213 9.6213L23.3787 3.8787C22.8161 3.31609 22.053 3.00001 21.2574 3H9C8.20435 3 7.44129 3.31607 6.87868 3.87868C6.31607 4.44129 6 5.20435 6 6V30C6 30.7956 6.31607 31.5587 6.87868 32.1213C7.44129 32.6839 8.20435 33 9 33ZM21 5.25H21.2571C21.3557 5.24979 21.4534 5.2691 21.5446 5.30682C21.6357 5.34453 21.7185 5.39991 21.7881 5.46975L27.5303 11.2119C27.6001 11.2815 27.6555 11.3643 27.6932 11.4554C27.7309 11.5466 27.7502 11.6443 27.75 11.7429V12H24C23.2044 12 22.4413 11.6839 21.8787 11.1213C21.3161 10.5587 21 9.79565 21 9V5.25ZM12 21C12 20.6022 12.158 20.2206 12.4393 19.9393C12.7206 19.658 13.1022 19.5 13.5 19.5H14.25V16.5C14.25 15.5054 14.6451 14.5516 15.3483 13.8483C16.0516 13.1451 17.0054 12.75 18 12.75C18.9946 12.75 19.9484 13.1451 20.6517 13.8483C21.3549 14.5516 21.75 15.5054 21.75 16.5V19.5H22.5C22.8978 19.5 23.2794 19.658 23.5607 19.9393C23.842 20.2206 24 20.6022 24 21V27C24 27.3978 23.842 27.7794 23.5607 28.0607C23.2794 28.342 22.8978 28.5 22.5 28.5H13.5C13.1022 28.5 12.7206 28.342 12.4393 28.0607C12.158 27.7794 12 27.3978 12 27V21ZM20.25 19.5H15.75V16.5C15.75 15.9033 15.9871 15.331 16.409 14.909C16.831 14.4871 17.4033 14.25 18 14.25C18.5967 14.25 19.169 14.4871 19.591 14.909C20.0129 15.331 20.25 15.9033 20.25 16.5V19.5Z" fill="#3772FF" />
        </svg>
    );
}

export default DIDsIcon;