import React from 'react';

function LinksBIcon() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.375 24.75V10.125C2.77826 10.125 2.20597 10.3621 1.78401 10.784C1.36205 11.206 1.125 11.7783 1.125 12.375V28.125C1.125 28.7217 1.36205 29.294 1.78401 29.716C2.20597 30.1379 2.77826 30.375 3.375 30.375H13.9275C11.8202 28.9111 9.31589 28.126 6.75 28.125C5.85489 28.125 4.99645 27.7694 4.36351 27.1365C3.73058 26.5036 3.375 25.6451 3.375 24.75ZM32.625 10.125V24.75C32.625 25.6451 32.2694 26.5036 31.6365 27.1365C31.0036 27.7694 30.1451 28.125 29.25 28.125C26.6841 28.126 24.1798 28.9111 22.0725 30.375H32.625C33.2217 30.375 33.794 30.1379 34.216 29.716C34.6379 29.294 34.875 28.7217 34.875 28.125V12.375C34.875 11.7783 34.6379 11.206 34.216 10.784C33.794 10.3621 33.2217 10.125 32.625 10.125Z" fill="#3772FF" />
            <path d="M6.75 5.625C6.45163 5.625 6.16548 5.74353 5.95451 5.95451C5.74353 6.16548 5.625 6.45163 5.625 6.75V24.75C5.625 25.0484 5.74353 25.3345 5.95451 25.5455C6.16548 25.7565 6.45163 25.875 6.75 25.875C10.1345 25.8785 13.4179 27.0285 16.065 29.1375L16.875 29.79V5.8275C16.5142 5.69652 16.1338 5.62804 15.75 5.625H6.75ZM30.375 24.75V6.75C30.375 6.45163 30.2565 6.16548 30.0455 5.95451C29.8345 5.74353 29.5484 5.625 29.25 5.625H20.25C19.8662 5.62804 19.4858 5.69652 19.125 5.8275V29.79L19.935 29.1375C22.5821 27.0285 25.8655 25.8785 29.25 25.875C29.5484 25.875 29.8345 25.7565 30.0455 25.5455C30.2565 25.3345 30.375 25.0484 30.375 24.75Z" fill="#3772FF" />
        </svg>
    );
}

export default LinksBIcon;